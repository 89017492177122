@import "./variables.scss";
@import "./reset.scss";
@import "./scroll.scss";
@import "./btn.scss";
@import "./chat.page.scss";
@import "./answer-references-component.scss";
@import "./loader.scss";
@import "./version.page.scss";
@import "./sessionMessage.scss";
@import "./form-container.scss";
@import "./react-select.scss";
@import "./detail-message.scss";

#root,
#root > .content,
#root > .content > .chatPage-body {
  width: 100%;
  height: 100vh;
}

body {
  min-width: auto !important;
}

.alert-inline {
  display: block;
  width: 100%;
  padding: 8px 20px 8px 52px;
  position: relative;
  min-height: 36px;
  margin: 16px 0;

  max-height: 130px;
  overflow-y: auto;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  p {
    display: block;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: 400;
    color: $black;

    &.bold {
      font-weight: 700;
    }
  }

  &.error {
    background: $red-200;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 10px;
      left: 20px;
      background: transparent
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEuMzEzNyAwSDQuNjg2MjlMMCA0LjY4NjI5VjExLjMxMzdMNC42ODYyOSAxNkgxMS4zMTM3TDE2IDExLjMxMzdWNC42ODYyOUwxMS4zMTM3IDBaTTQgNS41TDYuNSA4TDQgMTAuNUw1LjUgMTJMOCA5LjVMMTAuNSAxMkwxMiAxMC41TDkuNSA4TDEyIDUuNUwxMC41IDRMOCA2LjVMNS41IDRMNCA1LjVaIiBmaWxsPSIjQ0MwMDIyIi8+DQo8L3N2Zz4NCg==)
        no-repeat scroll 50% 50%;
      background-size: 16px 16px;
    }
  }

  &.success {
    background: $green-200;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 10px;
      left: 20px;
      background: transparent
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAsLTIpIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOCAxOEMxMi40MTgzIDE4IDE2IDE0LjQxODMgMTYgMTBDMTYgNS41ODE3MiAxMi40MTgzIDIgOCAyQzMuNTgxNzIgMiAwIDUuNTgxNzIgMCAxMEMwIDE0LjQxODMgMy41ODE3MiAxOCA4IDE4Wk0xMi43MDcxIDcuNzA3MTFMMTEuMjkyOSA2LjI5Mjg5TDYuNTg1NzkgMTFINi40MTQyMUw0LjcwNzExIDkuMjkyODlMMy4yOTI4OSAxMC43MDcxTDUuNTg1NzkgMTNINy40MTQyMUwxMi43MDcxIDcuNzA3MTFaIiBmaWxsPSIjMTg3QTE4Ii8+DQo8L2c+DQo8L3N2Zz4NCg==)
        no-repeat scroll 50% 50%;
      background-size: 16px 16px;
    }
  }

  &.info {
    background: $blue-200;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 10px;
      left: 20px;
      background: transparent
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOCAxNkMxMi40MTgzIDE2IDE2IDEyLjQxODMgMTYgOEMxNiAzLjU4MTcyIDEyLjQxODMgMCA4IDBDMy41ODE3MiAwIDAgMy41ODE3MiAwIDhDMCAxMi40MTgzIDMuNTgxNzIgMTYgOCAxNlpNNyA1VjNIOVY1SDdaTTkgMTBIMTFWMTJINVYxMEg3VjhINVY2SDlWMTBaIiBmaWxsPSIjMUY3MDk5Ii8+DQo8L3N2Zz4NCg==)
        no-repeat scroll 50% 50%;
      background-size: 16px 16px;
    }
  }

  &.warning {
    background: $yellow-200;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 9px;
      left: 20px;
      background: transparent
        url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNiAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTQgMTdIMkwwIDE1VjEzTDcgMkg5TDE2IDEzVjE1TDE0IDE3Wk03IDZIOVYxMUg3VjZaTTcgMTNIOVYxNUg3VjEzWiIgZmlsbD0iI0Y5OTAyRCIvPg0KPHBhdGggZD0iTTkgNkg3VjExSDlWNloiIGZpbGw9IiMzMzMzMzMiLz4NCjxwYXRoIGQ9Ik05IDEzSDdWMTVIOVYxM1oiIGZpbGw9IiMzMzMzMzMiLz4NCjwvc3ZnPg0K)
        no-repeat scroll 50% 50%;
      background-size: 16px 16px;
    }
  }
}
.wrapper-alert-inline.alert-inline-chat-message-user,
.wrapper-alert-inline.alert-inline-chat-message-ai {
  margin: 0 0;
}

.alert-inline-chat-message-user,
.alert-inline-chat-message-ai {
  font-size: 14px;
  line-height: 1.4;
  &.session_error > .alert-inline.error {
    width: 270px;
    min-height: 76px;
    border-radius: 9px 9px 9px 0;
    padding: 8px 12px 8px 40px;
  }
  &.chat-not-allowed > .alert-inline.warning {
    width:auto;
    min-height: auto;
    border-radius: 9px 9px 9px 0;
    padding: 8px 12px 8px 40px;
  }

  &.control {
    overflow: none !important;
    max-height: none !important;
  }
  & > .alert-inline.warning,
  .alert-inline.error {
    width: 218px;
    min-height: 56px;
    border-radius: 9px 9px 9px 0;
    padding: 8px 12px 8px 40px;
  }
  & > .alert-inline.info {
    width: 198px;
    height: 56px;
    border-radius: 9px 9px 9px 0;
    padding: 8px 12px 8px 40px;
  }
}

.alert-inline-chat-message-user {
  border-radius: 9px 9px 0px 9px;
}

.alert-inline-chat-message-ai {
  border-radius: 9px 9px 9px 0px;
}
